import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { GLOBAL_VAR } from '../models/global-variable';
import { ILogin } from '../models/login';
import { ISocialUser } from '../models/telegram';
import { IUser } from '../models/user';
import { LocalStorageService } from './local-storage.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAuth);
  authStatus: boolean = this.isAuth;
  constructor(
    private router: Router,
    private requestService: RequestService,
    private localStorageService: LocalStorageService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.authStatus != this.isAuth) {
          this.markStatusChange();
        }
      }
    });
  }

  login(data: {
    username: string;
    password: string;
    device: string;
    browser: string;
    os_version: string;
    public_ip: string;
  }): Observable<ILogin & ISocialUser> {
    return this.requestService.postJSON<ILogin & ISocialUser>('/client/account/login', { data }).pipe(
      map(res => {
        if (res?.token) {
          this.localStorageService.set(LocalStorageEnum.token, res.token);
          this.localStorageService.set(LocalStorageEnum.refresh_token, res.refresh_token);
          this.localStorageService.set(LocalStorageEnum.user_id, res.user._id!);
          this.markStatusChange();
        }
        return res;
      })
    );
  }

  logoutFromAPI() {
    return this.requestService.postJSON('/client/account/logout', { is_logout: true }).pipe(
      map(res => {
        return res;
      })
    );
  }

  logout(): Observable<string> {
    this.localStorageService.delete(LocalStorageEnum.token);
    this.localStorageService.delete(LocalStorageEnum.refresh_token);
    this.localStorageService.delete(LocalStorageEnum.user_id);
    this.localStorageService.delete(LocalStorageEnum.permissions);
    return new Observable<string>(observer => {
      observer.complete(); // complete function will be called when the observable is complete
      this.markStatusChange();
    });
  }

  private markStatusChange() {
    this.authChange$.next(this.isAuth);
    this.authStatus = this.isAuth;
  }

  get isAuth(): boolean {
    return this.localStorageService?.get(LocalStorageEnum.token) ? true : false;
  }

  redirectToHome(data: { token: string; refresh_token: string; user: IUser }): void {
    this.localStorageService.set(LocalStorageEnum.token, data.token);
    this.localStorageService.set(LocalStorageEnum.refresh_token, data.refresh_token);
    this.localStorageService.set(LocalStorageEnum.user_id, data.user._id!);
    this.markStatusChange();
    this.router.navigate([GLOBAL_VAR.ROUTE.HOME]);
  }

  setToken(data: { token: string; refresh_token: string; user: IUser }): void {
    this.localStorageService.set(LocalStorageEnum.token, data.token);
    this.localStorageService.set(LocalStorageEnum.refresh_token, data.refresh_token);
    this.localStorageService.set(LocalStorageEnum.user_id, data.user._id!);
    this.markStatusChange();
  }
}
